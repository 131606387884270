@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@layer utilities {
  @responsive {
    .buttonClass {
      --tw-bg-opacity: 0.5;
      background-color: rgb(192 132 252 / var(--tw-bg-opacity));
      border-radius: 1.5rem;
      margin: 1rem;
      padding: 0.5rem;
      height: fit-content;
      width: fit-content;
    }
    .questionButtonClass {
      --tw-bg-opacity: 0.4;

      padding: 0.5rem;
      height: fit-content;
      width: 16rem;
    } 
  }
}